import { Button } from "@progress/kendo-react-buttons";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { useField } from "formik";

interface ITextFieldProps {
  label: string;
  stateKey: string;
}

export const TextField = (props: ITextFieldProps) => {
  const [field, meta, helpers] = useField(props.stateKey);
  return (
    <p>
      <label>{props.label}</label>
      <br />
      <Input
        value={field.value}
        onChange={(e) => helpers.setValue(e.value)}
        onBlur={() => helpers.setTouched(true)}
        type={props.stateKey}
      />
      {meta.touched && Boolean(meta.error) ? meta.error : null}
    </p>
  );
};

interface ICheckboxFieldProps {
  label: string;
  stateKey: string;
}

export const CheckboxField = (props: ICheckboxFieldProps) => {
  const [field, meta, helpers] = useField(props.stateKey);
  return (
    <>
      <Checkbox
        checked={field.value}
        onChange={(e) => helpers.setValue(e.value)}
        onBlur={() => helpers.setTouched(true)}
        label={props.label}
      />
      {meta.touched && Boolean(meta.error) ? meta.error : null}
    </>
  );
};

interface ISubmitButtonProps {
  text: string;
  disabled: boolean;
}

export const SubmitButton = (props: ISubmitButtonProps) => {
  return (
    <Button className="ml-4" primary type="submit" disabled={props.disabled}>
      {props.text}
    </Button>
  );
};

interface ICancelButtonProps {
  text: string;
  disabled: boolean;
  onClick?: () => void;
}

export const CancelButton = (props: ICancelButtonProps) => {
  return (
    <Button
      look="outline"
      type="button"
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.text}
    </Button>
  );
};
