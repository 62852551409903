/* tslint:disable */
/* eslint-disable */
/**
 * Saga API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Result,
    ResultFromJSON,
    ResultFromJSONTyped,
    ResultToJSON,
} from './';

/**
 * 
 * @export
 * @interface ActionResult
 */
export interface ActionResult {
    /**
     * 
     * @type {Result}
     * @memberof ActionResult
     */
    result: Result;
    /**
     * 
     * @type {number}
     * @memberof ActionResult
     */
    resultId?: number;
    /**
     * 
     * @type {string}
     * @memberof ActionResult
     */
    domainExceptionName?: string | null;
}

export function ActionResultFromJSON(json: any): ActionResult {
    return ActionResultFromJSONTyped(json, false);
}

export function ActionResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ResultFromJSON(json['result']),
        'resultId': !exists(json, 'resultId') ? undefined : json['resultId'],
        'domainExceptionName': !exists(json, 'domainExceptionName') ? undefined : json['domainExceptionName'],
    };
}

export function ActionResultToJSON(value?: ActionResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ResultToJSON(value.result),
        'resultId': value.resultId,
        'domainExceptionName': value.domainExceptionName,
    };
}


