import { CheckboxField, SubmitButton, TextField } from "../form";
import { Form, Formik, FormikErrors, FormikHelpers } from "formik";
import { LoginResult, Result } from "../../api";
import { Card, CardActions, CardBody } from "@progress/kendo-react-layout";
import { Box, Grid } from "@material-ui/core";

type LoginFormState = {
  login: string;
  password: string;
  rememberMe: boolean;
};

interface ILoginFormProps {
  logon: (values: LoginFormState) => Promise<LoginResult>;
  onLogged: (rememberMe: boolean, loginResult: LoginResult) => void;
}

export const LoginForm = (props: ILoginFormProps) => {
  const initialValue: LoginFormState =
    process.env.NODE_ENV === "development"
      ? {
          login: "logaviv@logaviv.com",
          password: "Log@viv93",
          rememberMe: false,
        }
      : {
          login: "",
          password: "",
          rememberMe: false,
        };

  const onSubmit = async (
    values: LoginFormState,
    formikHelpers: FormikHelpers<LoginFormState>
  ) => {
    try {
      const loginResult = await props.logon(values);

      switch (loginResult.result) {
        case Result.Ok:
          props.onLogged(values.rememberMe, loginResult);
          break;
        case Result.Error:
          formikHelpers.setSubmitting(false);
          if (
            loginResult.domainExceptionName === "UserUnauthorized" ||
            loginResult.domainExceptionName === "PasswordIncorrect"
          )
            formikHelpers.setFieldError(
              "login",
              "Erreur d’identification, veuillez ressaisir les informations."
            );
          else formikHelpers.setFieldError("login", "Erreur");
          break;
      }
    } catch (error) {
      console.log(error);
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  const validate = (values: LoginFormState) => {
    const errors: FormikErrors<LoginFormState> = {};
    if (!values.login || values.login.length === 0) {
      errors.login = "* Obligatoire";
    }
    if (!values.password || values.password.length === 0) {
      errors.password = "* Obligatoire";
    }
    return errors;
  };

  return (
    <Formik
      initialValues={initialValue}
      onSubmit={onSubmit}
      validate={validate}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container justify="center">
            <Box minWidth={300} mt="15vh">
              <Card>
                <CardBody
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TextField label="Identifiant" stateKey="login" />
                  <TextField label="Mot de passe" stateKey="password" />
                  <CheckboxField
                    label="Rester connecté"
                    stateKey="rememberMe"
                  />
                </CardBody>
                <CardActions layout="end">
                  <SubmitButton text="Se connecter" disabled={isSubmitting} />
                </CardActions>
              </Card>
            </Box>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
