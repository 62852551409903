/* tslint:disable */
/* eslint-disable */
/**
 * Saga API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LoginInfos,
    LoginInfosFromJSON,
    LoginInfosToJSON,
    LoginResult,
    LoginResultFromJSON,
    LoginResultToJSON,
} from '../models';

export interface LoginAuthPostRequest {
    loginInfos: LoginInfos;
}

/**
 * 
 */
export class LoginApi extends runtime.BaseAPI {

    /**
     */
    async loginAuthPostRaw(requestParameters: LoginAuthPostRequest): Promise<runtime.ApiResponse<LoginResult>> {
        if (requestParameters.loginInfos === null || requestParameters.loginInfos === undefined) {
            throw new runtime.RequiredError('loginInfos','Required parameter requestParameters.loginInfos was null or undefined when calling loginAuthPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/Login/auth`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginInfosToJSON(requestParameters.loginInfos),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResultFromJSON(jsonValue));
    }

    /**
     */
    async loginAuthPost(loginInfos: LoginInfos): Promise<LoginResult> {
        const response = await this.loginAuthPostRaw({ loginInfos: loginInfos });
        return await response.value();
    }

    /**
     */
    async loginCurrentGetRaw(): Promise<runtime.ApiResponse<LoginResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Login/current`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResultFromJSON(jsonValue));
    }

    /**
     */
    async loginCurrentGet(): Promise<LoginResult> {
        const response = await this.loginCurrentGetRaw();
        return await response.value();
    }

}
