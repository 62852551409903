import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { FieldWrapper } from "@progress/kendo-react-form";
import { NumericTextBox, NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { Label, Error } from "@progress/kendo-react-labels";
import { useField } from "formik";
import React from "react";
import sagaSuggest from "./SagaSuggest";

function listNoDataRenderText() {
    return "Aucun résultat ...";
  }

export type SuggestFieldItemType<ItemType> = {
    values: ItemType[];
    onValueChanged?: (newValue: ItemType | null) => void | undefined;
    disabled?: boolean;
    openOnFocus?: boolean;
  };

export const SuggestField = <
  IState,
  ItemType extends { code: string; name: string }
>(
  stateKey: keyof IState,
  itemKey: keyof ItemType,
  label: string
) => {
  const ItemSuggest = sagaSuggest<ItemType>(
    (query, item) =>
      item.code.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
      item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0,
    (item) => (
      <div>
        <span style={{ color: "#AAAAAA" }}>{item.code}&nbsp;</span>
        <span>{item.name}</span>
      </div>
    ),
    (item) => item.code + (item.name ? " - " + item.name : "")
  );

  const Component = (props: SuggestFieldItemType<ItemType>) => {
    const { disabled } = { ...{ disabled: false }, ...props };
    const [field, meta, helpers] = useField(stateKey as string);
    const hasErrors = meta.touched && Boolean(meta.error);

    return (
      <ItemSuggest
        label={label}
        items={props.values}
        selectedItem={props.values.find((p) => p[itemKey] === field.value)}
        onItemSelect={(item) => {
          helpers.setValue(item ? item[itemKey] : "");
          if (props.onValueChanged) props.onValueChanged(item);
        }}
        dataItemKey={itemKey}
        openOnFocus={!props.openOnFocus}
        disabled={disabled}
        onBlur={() => helpers.setTouched(true)}
        hasErrors={hasErrors}
        errorMessage={meta.error}
        noResultsRender={listNoDataRenderText}
      />
    );
  };

  return Component;
};

export const DateField = <IState extends {}>(props: {
    stateKey: keyof IState;
    label?: string;
    disabled?: boolean;
    wrapped?: boolean;
  }) => {
    const { disabled } = { ...{ disabled: false }, ...props };
    const [field, meta, helpers] = useField(props.stateKey as string);
    const hasErrors = meta.touched && Boolean(meta.error);
    const { wrapped } = { ...{ wrapped: true }, ...props };
    const [open, setOpen] = React.useState(false);
  
    function onChange(event: DatePickerChangeEvent) {
      if (!event.value) {
        helpers.setValue(null);
        return;
      }
      var d = event.value;
      var date = new Date(d.getFullYear(), d.getMonth(), d.getDate());
      setOpen(false); // ICI ca fonctionne po :(
      helpers.setValue(date);
    }
  
    return (
      <>
        {wrapped ? (
          <FieldWrapper>
            {Boolean(props.label) && <Label>{props.label}</Label>}
                <DatePicker
                  format="dd/MM/yyyy"
                  value={field.value}
                  onChange={onChange}
                  onBlur={() => {
                    helpers.setTouched(true);
                    setOpen(false);
                  }}
                  popupSettings={{ animate: false }}
                  valid={!hasErrors}
                  disabled={disabled}
                  validationMessage=""
                  onFocus={() => setOpen(true)}
                  show={open}
                />
                {hasErrors && <Error>{meta.error}</Error>}
          </FieldWrapper>
        ) : (
          <FieldWrapper>
              <DatePicker
                format="dd/MM/yyyy"
                value={field.value}
                onChange={onChange}
                onBlur={() => {
                  helpers.setTouched(true);
                  setOpen(false);
                }}
                popupSettings={{ animate: false }}
                valid={!hasErrors}
                disabled={disabled}
                validationMessage=""
                onFocus={() => setOpen(true)}
                show={open}
              />
              {hasErrors && <Error>{meta.error}</Error>}
          </FieldWrapper>
        )}
      </>
    );
  };

export type NullableNumber = number | null;

export const NumericField = <IState extends {}>(props: {
    stateKey: keyof IState;
    label?: string;
    unit?: string;
    disabled?: boolean | undefined;
    onBlur?: (() => void) | undefined;
    onValueChanged?: (newValue: NullableNumber) => void;
    allowNegative?: boolean;
    allowDecimal?: boolean;
  }) => {
    const { disabled } = { ...{ disabled: false }, ...props };
    const [field, meta, helpers] = useField(props.stateKey as string);
    const hasErrors = meta.touched && Boolean(meta.error);
  
    const allowNegative = props.allowNegative ?? true;
    const allowDecimal = props.allowDecimal ?? true;
  
    function onBlur() {
      helpers.setTouched(true);
      if (props.onBlur) props.onBlur();
    }
  
    function onChange(e: NumericTextBoxChangeEvent) {
      helpers.setValue(e.value);
      if (props.onValueChanged) props.onValueChanged(e.value);
    }
  
    return (
      <FieldWrapper>
        {Boolean(props.label) && <Label>{props.label}</Label>}
        <NumericTextBox
          name={field.name}
          value={field.value}
          valid={!hasErrors}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          validationMessage=""
          min={!allowNegative ? 0 : undefined}
          format={allowDecimal ? "#.############" : "#"}
        />
        {Boolean(props.unit) && (
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            {props.unit}
          </div>
        )}
        {hasErrors && <Error>{meta.error}</Error>}
      </FieldWrapper>
    );
  };