import {
    ComboBox,
    ComboBoxChangeEvent,
    ComboBoxFilterChangeEvent,
    ListItemProps,
  } from "@progress/kendo-react-dropdowns";
  import { FieldWrapper } from "@progress/kendo-react-form";
  import { Label, Error } from "@progress/kendo-react-labels";
  import * as React from "react";
  
  export default function sagaSuggest<ItemType>(
    itemPredicate: (query: string, item: ItemType) => boolean,
    itemRenderer: (
      item: ItemType,
      itemProps: { index: number; focused: boolean; selected: boolean }
    ) => JSX.Element,
    inputValueRenderer: (item: ItemType) => string
  ) {
    type Props = {
      selectedItem?: ItemType | null;
      onItemSelect: (selectedItem: ItemType | null) => void;
      openOnFocus?: boolean;
      dataItemKey?: keyof ItemType;
      selectedId?: string;
      items: ItemType[];
      disabled?: boolean;
      onBlur?: () => void;
      hasErrors?: boolean;
      label?: string;
      errorMessage?: string;
      onQueryChange?: (query: string) => void;
      noResultsRender?: () => string;
    };
  
    type ComboBoxItem = ItemType & { itemLabel: string };
  
    function convert(item: ItemType): ComboBoxItem {
      return { ...item, itemLabel: inputValueRenderer(item) };
    }
  
    const Component = (props: Props) => {
      const {
        selectedItem,
        onItemSelect,
        openOnFocus,
        dataItemKey,
        selectedId,
        items,
        disabled,
        onBlur,
        hasErrors,
        label,
        errorMessage,
        onQueryChange,
        noResultsRender,
      } = { ...{ disabled: false, hasErrors: false }, ...props };
      const calculatedValue = React.useMemo<ComboBoxItem | null>(() => {
        if (selectedItem) return convert(selectedItem);
        if (selectedId && dataItemKey) {
          const item = items.find(
            (i) => ((i[dataItemKey] as unknown) as string) === selectedId
          );
          return item ? convert(item) : null;
        }
        return null;
      }, [dataItemKey, items, selectedId, selectedItem]);
  
      const [filter, setFilter] = React.useState("");
      const [isOpen, setIsOpen] = React.useState(false);
  
      const displayedItems = React.useMemo(
        () =>
          items
            .filter((n) => (filter ? itemPredicate(filter, n) : true))
            .map((n) => ({ ...n, itemLabel: inputValueRenderer(n) })),
        [items, filter]
      );
  
      function onChange(e: ComboBoxChangeEvent) {
        onItemSelect(e.target.value);
      }
  
      function onOpen() {
        setIsOpen(true);
      }
  
      function onClose() {
        setIsOpen(false);
        setFilter("");
      }
  
      function onFocus() {
        if (openOnFocus) setIsOpen(true);
      }
  
      function myRender(
        li: React.ReactElement<HTMLLIElement>,
        itemProps: ListItemProps
      ) {
        return React.cloneElement(
          li,
          li.props,
          itemRenderer(itemProps.dataItem, itemProps)
        );
      }
  
      function onFilterChange(evt: ComboBoxFilterChangeEvent) {
        const newFilter = evt.filter.value;
        setFilter(newFilter);
        if (onQueryChange) onQueryChange(newFilter);
      }
  
      function listNoDataRender(element: React.ReactElement<HTMLDivElement>) {
        if (!noResultsRender) return element;
        const elem = <h4 style={{ padding: "0 15px" }}>{noResultsRender()}</h4>;
        return React.cloneElement(element, element.props, elem);
      }
  
      return (
        <FieldWrapper>
          <Label>{label}</Label>
          <ComboBox
            data={displayedItems}
            opened={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            onFocus={onFocus}
            textField="itemLabel"
            dataItemKey={dataItemKey ? (dataItemKey as string) : undefined}
            value={calculatedValue}
            onChange={onChange}
            filterable={true}
            onFilterChange={onFilterChange}
            allowCustom={false}
            disabled={disabled}
            popupSettings={{ animate: false }}
            itemRender={myRender}
            onBlur={onBlur}
            valid={!hasErrors}
            listNoDataRender={listNoDataRender}
            validationMessage=""
          />
          {hasErrors && <Error>{errorMessage}</Error>}
        </FieldWrapper>
      );
    };
  
    return Component;
  }
