/* tslint:disable */
/* eslint-disable */
/**
 * Saga API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyToJSON,
    Contact,
    ContactFromJSON,
    ContactToJSON,
} from '../models';

export interface ShakaGetContactsByCompanyGetRequest {
    companyId?: number;
}

/**
 * 
 */
export class ShakaApi extends runtime.BaseAPI {

    /**
     */
    async shakaGetCompaniesGetRaw(): Promise<runtime.ApiResponse<Array<Company>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Shaka/GetCompanies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyFromJSON));
    }

    /**
     */
    async shakaGetCompaniesGet(): Promise<Array<Company>> {
        const response = await this.shakaGetCompaniesGetRaw();
        return await response.value();
    }

    /**
     */
    async shakaGetContactsByCompanyGetRaw(requestParameters: ShakaGetContactsByCompanyGetRequest): Promise<runtime.ApiResponse<Array<Contact>>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Shaka/GetContactsByCompany`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContactFromJSON));
    }

    /**
     */
    async shakaGetContactsByCompanyGet(companyId?: number): Promise<Array<Contact>> {
        const response = await this.shakaGetContactsByCompanyGetRaw({ companyId: companyId });
        return await response.value();
    }

}
