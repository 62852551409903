import { createContext } from "react";

export enum TokenSavedPlace {
    None = 1,
    LocalStorage = 2,
    SessionStorage = 3,
};

export const tokenKey = "token";

interface ITokenContext {
    token: string;
    tokenSavedPlace: TokenSavedPlace;
    setToken: (token: string, saveToken: boolean) => void;
}

export const TokenContext = createContext<ITokenContext>(
    {
        token: "",
        tokenSavedPlace: TokenSavedPlace.None,
        setToken: () => { }
    }
);

export function getTokenInformations(): { value: string, savedPlace: TokenSavedPlace } {
    if (localStorage[tokenKey])
        return { value: localStorage[tokenKey], savedPlace: TokenSavedPlace.LocalStorage };
    else if (sessionStorage[tokenKey])
        return { value: sessionStorage[tokenKey], savedPlace: TokenSavedPlace.SessionStorage };
    else
        return { value: "", savedPlace: TokenSavedPlace.None };
};
