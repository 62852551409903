import { createContext } from "react";

export type LoggedUser = {
    userId: number;
};

interface ILoggedUserContext {
    loggedUser: LoggedUser | null;
    setLoggedUser: (user: LoggedUser | null) => void;
}

export const LoggedUserContext = createContext<ILoggedUserContext>(
    {
        loggedUser: null,
        setLoggedUser: () => { }
    }
);