/* tslint:disable */
/* eslint-disable */
/**
 * Saga API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserWsd
 */
export interface UserWsd {
    /**
     * 
     * @type {number}
     * @memberof UserWsd
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserWsd
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserWsd
     */
    firstName: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserWsd
     */
    activated: boolean;
}

export function UserWsdFromJSON(json: any): UserWsd {
    return UserWsdFromJSONTyped(json, false);
}

export function UserWsdFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserWsd {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'lastName': json['lastName'],
        'firstName': json['firstName'],
        'activated': json['activated'],
    };
}

export function UserWsdToJSON(value?: UserWsd | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'lastName': value.lastName,
        'firstName': value.firstName,
        'activated': value.activated,
    };
}


