import * as React from "react";
import {
  Grid,
  GridColumn,
  GridRowProps,
  GridCellProps,
  GridExpandChangeEvent,
  GridDataStateChangeEvent,
  GridFilterCellProps,
  GridDetailRowProps,
  GridColumnMenuProps,
} from "@progress/kendo-react-grid";
import {
  CompositeFilterDescriptor,
  DataResult,
  process,
  State,
} from "@progress/kendo-data-query";
import {
  IntlProvider,
  load,
  loadMessages,
  LocalizationProvider,
} from "@progress/kendo-react-intl";
import { Popup } from "@progress/kendo-react-popup";
import { v4 as uuid } from "uuid";
import weekData from "cldr-data/supplemental/weekData.json";
import frMessages from "./i18n/fr.json";
import frNumbers from "cldr-data/main/fr/numbers.json";
import frLocalCurrency from "cldr-data/main/fr/currencies.json";
import frCaGregorian from "cldr-data/main/fr/ca-gregorian.json";
import frDateFields from "cldr-data/main/fr/dateFields.json";

export type FilterType = "text" | "numeric" | "boolean" | "date";
type AggregateFunction = "count" | "sum" | "average" | "min" | "max";

type ResultAggregate = {
  count: number;
  sum: number;
  average: number;
  min: number;
  max: number;
};

interface DataRowRender<T> {
  [key: string]:
  | ((
    dataItem: T,
    content: HTMLCollection
  ) => React.ReactElement<HTMLTableCellElement>)
  | undefined;
}

interface GroupRowRender {
  [key: string]: (
    row: ResultAggregate
  ) => React.ReactElement<HTMLTableCellElement>;
}
export type DataGridColumn<T> = {
  field: keyof T;
  locked?: boolean;
  resizable?: boolean;
  colMinResizableWidth?: number;
  header: string;
  filterable?: boolean;
  sortable?: boolean;
  groupable?: boolean;
  width?: number;
  filterType?: FilterType;
  displayFormat?: string;
  customCellRender?: (
    dataItem: T,
    content: HTMLCollection
  ) => React.ReactElement<HTMLTableCellElement>;
  aggregates?: {
    functions: AggregateFunction[];
    render: (
      result: ResultAggregate
    ) => React.ReactElement<HTMLTableCellElement>;
  };
  filterCell?: React.ComponentType<GridFilterCellProps>;
  columnMenu?: React.ComponentType<GridColumnMenuProps>;
};

const defaultGridOptions = {
  lineHeight: 12,
};

const defaultColumnProperties = {
  filterable: true,
  sortable: true,
  groupable: true,
  filterType: "text",
};

load(
  weekData,
  frNumbers,
  frLocalCurrency,
  frCaGregorian,
  frDateFields
);

loadMessages(frMessages, "fr");

export type DataResultExtended<T extends {}> = {
  data: T[];
  total: number;
};

export function dataGrid<ItemType>(gridOptions?: { lineHeight?: number }) {
  interface IProps {
    data: ItemType[] | DataResultExtended<ItemType>;
    columns: DataGridColumn<ItemType>[];
    defaultSorting?: { field: keyof ItemType; direction: "asc" | "desc" };
    defaultFilter?: CompositeFilterDescriptor;
    defaultGrouping?: Array<{
      field: keyof ItemType;
      direction: "asc" | "desc";
    }>;
    collapseOnGrouping?: boolean;
    contextMenu?: (row: ItemType) => React.ReactNode;
    resizable?: boolean;
    gridMinResizableWidth?: number;
    styleHeight?: string;
    detail?: React.ComponentType<GridDetailRowProps>;
    hasDetails?: (item: ItemType, dataIndex: number) => boolean;
    groupable?: boolean;
    onDataStateChanged?: (gridState: State) => void;
  }

  const options = { ...defaultGridOptions, ...(gridOptions || {}) };

  const gridClassName = "class" + uuid();

  return ((props: IProps) => {
    const {
      data,
      columns,
      defaultSorting,
      defaultFilter,
      defaultGrouping,
      resizable,
      gridMinResizableWidth,
      contextMenu,
      detail,
      hasDetails,
      groupable,
      onDataStateChanged,
      collapseOnGrouping,
    } = props;

    const [gridState, setGridState] = React.useState<State>({
      skip: 0,
      take: 20,
      sort: defaultSorting
        ? [
          {
            field: defaultSorting.field as string,
            dir: defaultSorting.direction,
          },
        ]
        : undefined,
      filter: defaultFilter,
      group: defaultGrouping?.map((n) => ({
        field: n.field as string,
        dir: n.direction,
        aggregates: columns.flatMap((n) =>
          (n.aggregates?.functions ?? []).map((f) => ({
            field: n.field as string,
            aggregate: f,
          }))
        ),
      })),
    });

    React.useEffect(() => {
      if (onDataStateChanged) onDataStateChanged(gridState);
    }, [gridState, onDataStateChanged]);

    const [showContextMenu, setShowContextMenu] = React.useState(false);
    const [refreshNeeded, setRefreshNeeded] = React.useState(false);
    const [displayWithMinWidth, setDisplayWithMinWidth] = React.useState(false);

    const [contextMenuParams, setContextMenuParams] = React.useState({
      offset: {
        left: 0,
        top: 0,
      },
      dataItem: {} as ItemType,
    });

    React.useEffect(() => {
      document.addEventListener("click", onDocumentClick);
      return () => document.removeEventListener("click", onDocumentClick);
    });

    React.useEffect(() => {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    });

    React.useLayoutEffect(() => {
      setTimeout(() => handleResize(), 1);
    });

    React.useEffect(() => {
      const grid = document.getElementsByClassName(gridClassName);
      if (grid.length === 0) return;
      const contentDiv = grid[0].getElementsByClassName("k-grid-content");
      if (contentDiv.length === 0) return;
      contentDiv[0].scroll({ top: 0 });
    }, [gridState.skip]);

    const mappedColumns = columns.map((c) => ({
      ...defaultColumnProperties,
      ...c,
    }));

    const minGridWidth = mappedColumns
      .map((n) => (n.width ? n.width : 0))
      .reduce((p, c) => p + c);

    let dataRowsRender = React.useMemo(() => {
      let res: DataRowRender<ItemType> = {};
      columns.forEach((c) => (res[c.field as string] = c.customCellRender));
      return res;
    }, [columns]);

    let groupsRender = React.useMemo(() => {
      let res: GroupRowRender = {};
      columns.forEach(
        (c) =>
        (res[c.field as string] = c.aggregates
          ? c.aggregates.render
          : () => <td />)
      );
      return res;
    }, [columns]);

    let language = "fr";

    function onDocumentClick() {
      if (!showContextMenu) return;
      setShowContextMenu(false);
    }

    function handleResize() {
      const grid = document.querySelector(".k-grid") as HTMLDivElement;

      if (grid && grid.offsetWidth < minGridWidth) {
        if (!displayWithMinWidth) setRefreshNeeded(true);
        setDisplayWithMinWidth(true);
      } else if (grid && grid.offsetWidth > minGridWidth) {
        if (displayWithMinWidth) setRefreshNeeded(true);
        setDisplayWithMinWidth(false);
      }
    }

    function onDataStateChange(evt: GridDataStateChangeEvent) {
      const aggregates = columns
        .filter((c) => c.aggregates !== undefined)
        .flatMap((c) =>
          c.aggregates
            ? [
              ...c.aggregates.functions.map((f) => ({
                field: c.field as string,
                aggregate: f,
              })),
            ]
            : []
        );

      const newGridState: State = {
        ...evt.dataState,
        group: evt.dataState.group
          ? evt.dataState.group.map((grp) => ({ ...grp, aggregates }))
          : undefined,
      };

      setGridState(newGridState);
    }

    function onExpandChange(evt: GridExpandChangeEvent) {
      evt.dataItem[evt.target.props.expandField || ""] = evt.value;
      evt.target.setState({});
    }

    function rowRender(
      trElement: React.ReactElement<HTMLTableRowElement>,
      gridRowProps: GridRowProps
    ) {
      if (!contextMenu || gridRowProps.rowType !== "data") return trElement;
      const trProps = {
        ...trElement.props,
        onContextMenu: (e: any) => {
          e.preventDefault();
          setShowContextMenu(true);
          setContextMenuParams({
            offset: {
              left: e.clientX,
              top: e.clientY,
            },
            dataItem: gridRowProps.dataItem,
          });
        }
      };
      return React.cloneElement(
        trElement,
        { ...trProps },
        trElement.props.children
      );
    }

    function cellRender(
      tdElement: React.ReactElement<HTMLTableCellElement> | null,
      cellProps: GridCellProps
    ) {
      if (
        cellProps.rowType === "groupFooter" &&
        cellProps.field !== undefined
      ) {
        const groupRenderFunc = groupsRender[cellProps.field];

        if (groupRenderFunc)
          return groupRenderFunc(
            cellProps.dataItem.aggregates[cellProps.field]
          );
      } else if (
        cellProps.rowType === "data" &&
        cellProps.field === "isExpanded" &&
        hasDetails !== undefined &&
        !hasDetails(cellProps.dataItem, cellProps.dataIndex)
      ) {
        return <td>&nbsp;</td>;
      } else if (
        cellProps.rowType === "data" &&
        cellProps.field !== undefined &&
        tdElement
      ) {
        const rowRenderFunc = dataRowsRender[cellProps.field];

        if (rowRenderFunc)
          return rowRenderFunc(
            cellProps.dataItem as ItemType,
            tdElement.props.children
          );
      }
      return tdElement;
    }

    let displayedData: DataResult;

    if ("total" in data) displayedData = { ...data };
    else {
      displayedData = process(data, gridState);
      if (collapseOnGrouping !== undefined && collapseOnGrouping)
        displayedData.data.forEach((d) => {
          if (
            "items" in d &&
            "aggregates" in d &&
            "field" in d &&
            "value" in d
          ) {
            //on est sur un groupement
            if (!("isExpanded" in d)) d.isExpanded = false;
          }
        });
    }

    if (
      gridState.skip !== undefined &&
      gridState.skip > 0 &&
      displayedData.total <= gridState.skip
    ) {
      setTimeout(() => setGridState((prev) => ({ ...prev, skip: 0 })), 100);
    }

    if (refreshNeeded) {
      setTimeout(() => setRefreshNeeded(false), 1);
      return null;
    }

    return (
      <>
        {contextMenu && (
          <Popup show={showContextMenu} offset={contextMenuParams.offset}>
            <div onClick={onDocumentClick}>
              {contextMenu(contextMenuParams.dataItem)}
            </div>
          </Popup>
        )}
        <LocalizationProvider language={language}>
          <IntlProvider locale={language}>
            <Grid
              className={gridClassName}
              detail={detail}
              style={{
                height: props.styleHeight ? props.styleHeight : "calc(100%)",
                lineHeight: options.lineHeight + "px",
              }}
              {...displayedData}
              {...gridState}
              pageable={true}
              filterable={true}
              sortable={true}
              groupable={
                groupable === undefined || groupable
                  ? columns.some(
                    (c) => c !== undefined && c.aggregates !== undefined
                  )
                    ? { footer: "visible" }
                    : true
                  : false
              }
              expandField="isExpanded"
              onDataStateChange={onDataStateChange}
              onExpandChange={onExpandChange}
              rowRender={rowRender}
              cellRender={cellRender}
              resizable={resizable ?? true}
            >
              {mappedColumns.map((c) => (
                <GridColumn
                  key={c.field as string}
                  field={c.field as string}
                  title={c.header}
                  filterable={c.filterable}
                  sortable={c.sortable}
                  groupable={
                    c.groupable &&
                    !(gridState.group || []).find((g) => g.field === c.field)
                  }
                  width={
                    displayWithMinWidth
                      ? c.width
                        ? c.width + "px"
                        : undefined
                      : undefined
                  }
                  filter={c.filterType as FilterType}
                  format={c.displayFormat}
                  locked={c.locked}
                  resizable={c.resizable}
                  minResizableWidth={
                    c.colMinResizableWidth
                      ? c.colMinResizableWidth
                      : gridMinResizableWidth
                  }
                  filterCell={c.filterCell}
                  columnMenu={c.columnMenu}
                />
              ))}
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </>
    );
  });
}