import { useContext, useMemo } from "react";
import { Configuration, HTTPHeaders } from "../api";
import { TokenContext } from "../contexts";

const basePath = window.location.protocol + "//" + window.location.host;

function useApiConfiguration() {
    const { token } = useContext(TokenContext);

    const apiConfiguration = useMemo(() => {

        const headers: HTTPHeaders = {
            "Content-Type": "application/json"
        };

        if (token)
            headers["Authorization"] = "Bearer " + token;

        return new Configuration({
            basePath,
            headers
        });
    }, [token]);

    return apiConfiguration;
}

export function useApi<T>(
    apiClass: new (c: Configuration) => T
): T {
    const config = useApiConfiguration();
    const apiInstance = useMemo(() => new apiClass(config), [config, apiClass]);
    return apiInstance;
}