/* tslint:disable */
/* eslint-disable */
/**
 * Saga API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Country,
    CountryFromJSON,
    CountryFromJSONTyped,
    CountryToJSON,
} from './';

/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {number}
     * @memberof Contact
     */
    contactId: number;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    firstName: string;
    /**
     * 
     * @type {number}
     * @memberof Contact
     */
    countryId: number;
    /**
     * 
     * @type {Country}
     * @memberof Contact
     */
    country?: Country;
}

export function ContactFromJSON(json: any): Contact {
    return ContactFromJSONTyped(json, false);
}

export function ContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): Contact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contactId': json['contactId'],
        'lastName': json['lastName'],
        'firstName': json['firstName'],
        'countryId': json['countryId'],
        'country': !exists(json, 'country') ? undefined : CountryFromJSON(json['country']),
    };
}

export function ContactToJSON(value?: Contact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contactId': value.contactId,
        'lastName': value.lastName,
        'firstName': value.firstName,
        'countryId': value.countryId,
        'country': CountryToJSON(value.country),
    };
}


