/* tslint:disable */
/* eslint-disable */
/**
 * Saga API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Account,
    AccountFromJSON,
    AccountToJSON,
    ActionResult,
    ActionResultFromJSON,
    ActionResultToJSON,
} from '../models';

export interface AccountEditAccountPostRequest {
    account?: Account;
}

export interface AccountGetAccountByIdIdGetRequest {
    id: number;
}

export interface AccountSaveAccountPostRequest {
    account?: Account;
}

/**
 * 
 */
export class AccountApi extends runtime.BaseAPI {

    /**
     */
    async accountEditAccountPostRaw(requestParameters: AccountEditAccountPostRequest): Promise<runtime.ApiResponse<ActionResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/Account/EditAccount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountToJSON(requestParameters.account),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActionResultFromJSON(jsonValue));
    }

    /**
     */
    async accountEditAccountPost(account?: Account): Promise<ActionResult> {
        const response = await this.accountEditAccountPostRaw({ account: account });
        return await response.value();
    }

    /**
     */
    async accountGetAccountByIdIdGetRaw(requestParameters: AccountGetAccountByIdIdGetRequest): Promise<runtime.ApiResponse<Account>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accountGetAccountByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Account/GetAccountById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountFromJSON(jsonValue));
    }

    /**
     */
    async accountGetAccountByIdIdGet(id: number): Promise<Account> {
        const response = await this.accountGetAccountByIdIdGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async accountGetAccountsGetRaw(): Promise<runtime.ApiResponse<Array<Account>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Account/GetAccounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AccountFromJSON));
    }

    /**
     */
    async accountGetAccountsGet(): Promise<Array<Account>> {
        const response = await this.accountGetAccountsGetRaw();
        return await response.value();
    }

    /**
     */
    async accountSaveAccountPostRaw(requestParameters: AccountSaveAccountPostRequest): Promise<runtime.ApiResponse<ActionResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/Account/SaveAccount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountToJSON(requestParameters.account),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActionResultFromJSON(jsonValue));
    }

    /**
     */
    async accountSaveAccountPost(account?: Account): Promise<ActionResult> {
        const response = await this.accountSaveAccountPostRaw({ account: account });
        return await response.value();
    }

}
