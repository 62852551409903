import { useMemo } from "react";
import { useHistory } from "react-router";

export function useNavigator() {
    const { push, goBack, goForward } = useHistory();

    const navigator = useMemo(() => {
        return {
            goToHome: () => {
                push("/home");
            },
            goToAccountListing: () => {
                push("/accountListing");
            },
            goToStatistic : () =>{
                push("/statistics")
            },
            goToAccountCreation : () =>{
                push("/accountManager/add")
            },
            goToAccountEdition: (accountId?: number, action?: "duplicate" | "edit") => {
                push(`/accountManager/${action}/${accountId}`);
            },
            goBack,
            goForward
        }
    }, [goBack, goForward, push]);

    return navigator;
}
