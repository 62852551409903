import { Button } from "@progress/kendo-react-buttons";
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
} from "@progress/kendo-react-layout";
import { useContext } from "react";
import { LoggedUserContext } from "../../contexts";
import { useNavigator } from "../../hooks";
import { DisconnectButton } from "../login";

export const MenuBar = () => {
  const { loggedUser } = useContext(LoggedUserContext);
  const navigator = useNavigator();

  return (
    <>
      <AppBar positionMode="sticky" themeColor="inherit" className="menu">
        <AppBarSection>
          <h1>Saga</h1>
        </AppBarSection>

        <AppBarSpacer style={{ width: 32 }} />

        {loggedUser && (
          <AppBarSection>
            <ul>
              <li>
                <Button look="flat" onClick={() => navigator.goToHome()}>
                  Accueil
                </Button>
              </li>
              <li>
                <Button
                  look="flat"
                  onClick={() => navigator.goToAccountListing()}
                >
                  Gestion utilisateurs
                </Button>
              </li>
              <li>
                <Button look="flat" onClick={() => navigator.goToStatistic()}>
                  Statistiques
                </Button>
              </li>
            </ul>
          </AppBarSection>
        )}

        <AppBarSpacer />

        {loggedUser && (
          <DisconnectButton>
            {(logout) => <Button look="flat" icon="logout" onClick={logout} />}
          </DisconnectButton>
        )}
      </AppBar>
      <style>{`
                .menu h1 {
                    font-size: 18px;
                    margin: 0;
                }
                .menu ul {
                    font-size: 14px;
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    display: flex;
                }
                .menu li {
                    margin: 0 10px;
                }
                .menu li:hover {
                    cursor: pointer;
                    color: #84cef1;
                }
                .menu .k-button {
                    padding: 0;
                }
                .menu .k-badge-container {
                    margin-right: 8px;
                }
                .menu a {

                }
                .menu a:link { color: white; text-decoration: none; }     
                .menu a:visited { color: white; text-decoration: none; }
                .menu a:hover { color: #DDDDDD; text-decoration: none; }
                .menu a:active { color: white; text-decoration: none; } 
            `}</style>
    </>
  );
};
