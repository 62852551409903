import { useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { LoginApi, LoginInfos, LoginResult, Result } from "../../api";
import {
  TokenContext,
  LoggedUserContext,
  TokenSavedPlace,
} from "../../contexts";
import { useApi } from "../../hooks";
import { LoginForm } from "./LoginForm";

export const AutoLogin = () => {
  const { token, tokenSavedPlace, setToken } = useContext(TokenContext);
  const { loggedUser, setLoggedUser } = useContext(LoggedUserContext);
  const loginApi = useApi(LoginApi);

  const logon = async (values: LoginInfos) => {
    return await loginApi.loginAuthPost(values);
  };

  const onLogged = (rememberMe: boolean, loginResult: LoginResult) => {
    ReactDOM.unstable_batchedUpdates(() => {
      if (loginResult.resultId) {
        // l'ordre est important si on ne veut pas déclencher inutilement le runEffect ci-dessous
        setLoggedUser({ userId: loginResult.resultId });
        setToken(loginResult.token, rememberMe);
      }
    });
  };

  useEffect(() => {
    let didCancel = false;

    const runEffect = async () => {
      if (token && !loggedUser) {
        // on a un token sauvegardé, mais l'utilisateur n'est pas connecté : on le connecte automatiquement !
        try {
          console.log("automatic logon... ");
          const loginResult = await loginApi.loginCurrentGet();

          if (
            !didCancel &&
            loginResult.result === Result.Ok &&
            loginResult.resultId
          ) {
            // l'ordre est important si on ne veut pas déclencher à nouveau le runEffect
            setLoggedUser({ userId: loginResult.resultId });
            setToken(
              loginResult.token,
              tokenSavedPlace === TokenSavedPlace.LocalStorage
            );
          }
        } catch {
          console.error("unable to connect");
          setToken("", false);
        }
      }
    };

    runEffect();
    return () => {
      didCancel = true;
    };
  }, [token, loggedUser, loginApi, setToken, setLoggedUser, tokenSavedPlace]);

  if (token && !loggedUser) return <div>connexion en cours...</div>;

  if (!loggedUser) return <LoginForm logon={logon} onLogged={onLogged} />;

  return null;
};
