import { useContext } from "react";
import { TokenContext, LoggedUserContext } from "../../contexts";

interface IDisconnectButtonProps {
    children: (logout: () => void) => JSX.Element;
}

export const DisconnectButton = (props: IDisconnectButtonProps) => {
    const { setToken } = useContext(TokenContext);
    const { setLoggedUser } = useContext(LoggedUserContext);

    const logout = () => {
        setToken("", true);
        setLoggedUser(null);
    };

    return <>{props.children(logout)}</>
};