import React, { useContext } from "react";
import { Route } from "react-router";
import { LoggedUserContext } from "../../contexts";
import { AccountManager, Home, Statistics } from "../../views";
import { AutoLogin } from "../login";
import { AccountListing } from "../../views/AccountListing";

export const ViewsRouter = () => {
  const { loggedUser } = useContext(LoggedUserContext);

  if (!loggedUser) return <AutoLogin />;

  return (
    <>
      <Route path="/home">
        <Home />
      </Route>
      <Route path="/accountListing">
        <AccountListing />
      </Route>
      <Route path="/statistics">
        <Statistics />
      </Route>
      <Route path="/accountManager/:action?/:accountId?">
        <AccountManager />
      </Route>
    </>
  );
};
