/* tslint:disable */
/* eslint-disable */
/**
 * Saga API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Result {
    NoResult = 'NoResult',
    Ok = 'Ok',
    Error = 'Error',
    DomainException = 'DomainException'
}

export function ResultFromJSON(json: any): Result {
    return ResultFromJSONTyped(json, false);
}

export function ResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): Result {
    return json as Result;
}

export function ResultToJSON(value?: Result | null): any {
    return value as any;
}

