import "@progress/kendo-theme-default/dist/all.css";
import "./App.css";
import { useState } from "react";
import {
  TokenContext,
  LoggedUser,
  LoggedUserContext,
  TokenSavedPlace,
  getTokenInformations,
  tokenKey,
} from "./contexts";
import { MenuBar } from "./components/menu";
import { BrowserRouter } from "react-router-dom";
import { ViewsRouter } from "./components/routes";
import { Box } from "@material-ui/core";

function App() {
  const [tokenInformations, setTokenInformations] = useState(
    getTokenInformations()
  );
  const [loggedUser, setLoggedUser] = useState<LoggedUser | null>(null);

  const saveToken = (t: string, saveToken: boolean) => {
    localStorage[tokenKey] = saveToken ? t : "";
    sessionStorage[tokenKey] = t;
    setTokenInformations({
      value: t,
      savedPlace: saveToken
        ? TokenSavedPlace.LocalStorage
        : TokenSavedPlace.SessionStorage,
    });
  };

  return (
    <TokenContext.Provider
      value={{
        token: tokenInformations.value,
        tokenSavedPlace: tokenInformations.savedPlace,
        setToken: saveToken,
      }}
    >
      <LoggedUserContext.Provider value={{ loggedUser, setLoggedUser }}>
        <BrowserRouter>
          <MenuBar />
          <Box className="main-container">
            <ViewsRouter />
          </Box>
        </BrowserRouter>
      </LoggedUserContext.Provider>
    </TokenContext.Provider>
  );
}

export default App;
